        <template>
  <b-card>
    <b-row
          >
                                    <b-col
                                                                                      sm="12"
                                        >
                            <html-content
  api="https://api.hoglabest.com/api/v1/htm/lk/service/data/page/academy"
  />            </b-col>
                            </b-row>
  </b-card>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'


                                      import HtmlContent from '@core/components/html-content/HtmlContent.vue'
                            
export default {
  components: {
    BCard,
    BRow,
    BCol,

                                                          HtmlContent,
                                          },
  setup() {
    
      },
}
</script>
    